const schools = [
  {
    id: "101",
    slug: "arlington",
    name: "Arlington",
    websiteURL: "https://arlington.tacomaschools.org/",
    contactsURL: "https://arlington.tacomaschools.org/contact",
    flyersURL: "https://app.peachjar.com/flyers/all/schools/25714"
  },
  {
    id: "103",
    slug: "birney",
    name: "Birney",
    websiteURL: "https://birney.tacomaschools.org/",
    contactsURL: "https://www.tacomaschools.org/contact",
    flyersURL: "https://app.peachjar.com/flyers/all/schools/25716"
  },
  {
    id: "104",
    slug: "blix",
    name: "Blix",
    websiteURL: "https://blix.tacomaschools.org/",
    contactsURL: "https://www.tacomaschools.org/contact",
    flyersURL: "https://app.peachjar.com/flyers/all/schools/25717"
  },
  {
    id: "105",
    slug: "boze",
    name: "Boze",
    websiteURL: "https://boze.tacomaschools.org/",
    contactsURL: "https://boze.tacomaschools.org/contact",
    flyersURL: "https://app.peachjar.com/flyers/all/schools/25718"
  },
  {
    id: "107",
    slug: "bp",
    name: "Browns Point",
    websiteURL: "https://bp.tacomaschools.org/",
    contactsURL: "https://bp.tacomaschools.org/contact",
    flyersURL: "https://app.peachjar.com/flyers/all/schools/25719"
  },
  {
    id: "110",
    slug: "ch",
    name: "Crescent Heights",
    websiteURL: "https://ch.tacomaschools.org/",
    contactsURL: "https://ch.tacomaschools.org/contact",
    flyersURL: "https://app.peachjar.com/flyers/all/schools/25722"
  },
  {
    id: "113",
    slug: "delong",
    name: "DeLong",
    websiteURL: "https://delong.tacomaschools.org/",
    contactsURL: "https://delong.tacomaschools.org/contact",
    flyersURL: "https://app.peachjar.com/flyers/all/schools/25724"
  },
  {
    id: "115",
    slug: "downing",
    name: "Downing",
    websiteURL: "https://downing.tacomaschools.org/",
    contactsURL: "https://downing.tacomaschools.org/contact",
    flyersURL: "https://app.peachjar.com/flyers/all/schools/25725"
  },
  {
    id: "117",
    slug: "edison",
    name: "Edison",
    websiteURL: "https://edison.tacomaschools.org/edison",
    contactsURL: "https://edison.tacomaschools.org/contact",
    flyersURL: "https://app.peachjar.com/flyers/all/schools/25726"
  },
  {
    id: "119",
    slug: "fawcett",
    name: "Fawcett",
    websiteURL: "https://fawcett.tacomaschools.org/",
    contactsURL: "https://fawcett.tacomaschools.org/contact",
    flyersURL: "https://app.peachjar.com/flyers/all/schools/25727"
  },
  {
    id: "121",
    slug: "fh",
    name: "Fern Hill",
    websiteURL: "https://fh.tacomaschools.org/",
    contactsURL: "https://fh.tacomaschools.org/contact",
    flyersURL: "https://app.peachjar.com/flyers/all/schools/25728"
  },
  {
    id: "123",
    slug: "franklin",
    name: "Franklin",
    websiteURL: "https://franklin.tacomaschools.org/",
    contactsURL: "https://franklin.tacomaschools.org/contact",
    flyersURL: "https://app.peachjar.com/flyers/all/schools/25730"
  },
  {
    id: "125",
    slug: "geiger",
    name: "Geiger",
    websiteURL: "https://geiger.tacomaschools.org/",
    contactsURL: "https://geiger.tacomaschools.org/contact",
    flyersURL: "https://app.peachjar.com/flyers/all/schools/25732"
  },
  {
    id: "127",
    slug: "grant",
    name: "Grant",
    websiteURL: "https://grant.tacomaschools.org/",
    contactsURL: "https://grant.tacomaschools.org/contact",
    flyersURL: "https://app.peachjar.com/flyers/all/schools/25733"
  },
  {
    id: "129",
    slug: "stafford",
    name: "Stafford",
    websiteURL: "https://stafford.tacomaschools.org/",
    contactsURL: "https://stafford.tacomaschools.org/contact",
    flyersURL: "https://app.peachjar.com/flyers/all/schools/25735"
  },
  {
    id: "133",
    slug: "jefferson",
    name: "Jefferson",
    websiteURL: "https://jefferson.tacomaschools.org/",
    contactsURL: "https://jefferson.tacomaschools.org/contact",
    flyersURL: "https://app.peachjar.com/flyers/all/schools/25739"
  },
  {
    id: "135",
    slug: "larchmont",
    name: "Larchmont",
    websiteURL: "https://larchmont.tacomaschools.org/",
    contactsURL: "https://larchmont.tacomaschools.org/contact",
    flyersURL: "https://app.peachjar.com/flyers/all/schools/25740"
  },
  {
    id: "137",
    slug: "lister",
    name: "Lister",
    websiteURL: "https://lister.tacomaschools.org/",
    contactsURL: "https://lister.tacomaschools.org/contact",
    flyersURL: "https://app.peachjar.com/flyers/all/schools/25742"
  },
  {
    id: "139",
    slug: "lowell",
    name: "Lowell",
    websiteURL: "https://lowell.tacomaschools.org/",
    contactsURL: "https://lowell.tacomaschools.org/contact",
    flyersURL: "https://app.peachjar.com/flyers/all/schools/25743"
  },
  {
    id: "143",
    slug: "lyon",
    name: "Lyon",
    websiteURL: "https://lyon.tacomaschools.org/",
    contactsURL: "https://lyon.tacomaschools.org/contact",
    flyersURL: "https://app.peachjar.com/flyers/all/schools/25744"
  },
  {
    id: "145",
    slug: "madison",
    name: "Madison",
    websiteURL: "https://www.tacomaschools.org/departments/early-learning-preschool",
    contactsURL:
      "https://www.tacomaschools.org/departments/early-learning-preschool",
    flyersURL: "https://app.peachjar.com/flyers/all/schools/25745"
  },
  {
    id: "147",
    slug: "mp",
    name: "Manitou Park",
    websiteURL: "https://mp.tacomaschools.org/",
    contactsURL: "https://mp.tacomaschools.org/contact",
    flyersURL: "https://app.peachjar.com/flyers/all/schools/25746"
  },
  {
    id: "149",
    slug: "mann",
    name: "Mann",
    websiteURL: "https://mann.tacomaschools.org/",
    contactsURL: "https://mann.tacomaschools.org/contact",
    flyersURL: "https://app.peachjar.com/flyers/all/schools/25747"
  },
  {
    id: "151",
    slug: "mccarver",
    name: "McCarver",
    websiteURL: "https://mccarver.tacomaschools.org/",
    contactsURL: "https://mccarver.tacomaschools.org/contact",
    flyersURL: "https://app.peachjar.com/flyers/all/schools/25749"
  },
  {
    id: "157",
    slug: "nt",
    name: "Northeast Tacoma",
    websiteURL: "https://nt.tacomaschools.org/",
    contactsURL: "https://nt.tacomaschools.org/contact",
    flyersURL: "https://app.peachjar.com/flyers/all/schools/25754"
  },
  {
    id: "163",
    slug: "pd",
    name: "Point Defiance",
    websiteURL: "https://pd.tacomaschools.org/",
    contactsURL: "https://pd.tacomaschools.org/contact",
    flyersURL: "https://app.peachjar.com/flyers/all/schools/25758"
  },
  {
    id: "165",
    slug: "reed",
    name: "Reed",
    websiteURL: "https://reed.tacomaschools.org/",
    contactsURL: "https://reed.tacomaschools.org/contact",
    flyersURL: "https://app.peachjar.com/flyers/all/schools/25759"
  },
  {
    id: "169",
    slug: "roosevelt",
    name: "Roosevelt",
    websiteURL: "https://roosevelt.tacomaschools.org/",
    contactsURL: "https://roosevelt.tacomaschools.org/contact",
    flyersURL: "https://app.peachjar.com/flyers/all/schools/25761"
  },
  {
    id: "175",
    slug: "sheridan",
    name: "Sheridan",
    websiteURL: "https://sheridan.tacomaschools.org/",
    contactsURL: "https://sheridan.tacomaschools.org/contact",
    flyersURL: "https://app.peachjar.com/flyers/all/schools/25762"
  },
  {
    id: "177",
    slug: "sherman",
    name: "Sherman",
    websiteURL: "https://sherman.tacomaschools.org/",
    contactsURL: "https://sherman.tacomaschools.org/contact",
    flyersURL: "https://app.peachjar.com/flyers/all/schools/25763"
  },
  {
    id: "179",
    slug: "stanley",
    name: "Stanley",
    websiteURL: "https://stanley.tacomaschools.org/",
    contactsURL: "https://stanley.tacomaschools.org/contact",
    flyersURL: "https://app.peachjar.com/flyers/all/schools/25766"
  },
  {
    id: "181",
    slug: "skyline",
    name: "Skyline",
    websiteURL: "https://skyline.tacomaschools.org/",
    contactsURL: "https://skyline.tacomaschools.org/contact",
    flyersURL: "https://app.peachjar.com/flyers/all/schools/25764"
  },
  {
    id: "185",
    slug: "washington",
    name: "Washington",
    websiteURL: "https://washington.tacomaschools.org/",
    contactsURL: "https://washington.tacomaschools.org/contact",
    flyersURL: "https://app.peachjar.com/flyers/all/schools/25772"
  },
  {
    id: "187",
    slug: "whitman",
    name: "Whitman",
    websiteURL: "https://whitman.tacomaschools.org/",
    contactsURL: "https://whitman.tacomaschools.org/contact",
    flyersURL: "https://app.peachjar.com/flyers/all/schools/25773"
  },
  {
    id: "189",
    slug: "whittier",
    name: "Whittier",
    websiteURL: "https://whittier.tacomaschools.org/",
    contactsURL: "https://whittier.tacomaschools.org/contact",
    flyersURL: "https://app.peachjar.com/flyers/all/schools/25774"
  },
  {
    id: "200",
    slug: "giaudrone",
    name: "Giaudrone",
    websiteURL: "https://giaudrone.tacomaschools.org/",
    contactsURL: "https://giaudrone.tacomaschools.org/contact",
    flyersURL: "https://app.peachjar.com/flyers/all/schools/25713"
  },
  {
    id: "202",
    slug: "baker",
    name: "Baker",
    websiteURL: "https://baker.tacomaschools.org/",
    contactsURL: "https://baker.tacomaschools.org/contact",
    flyersURL: "https://app.peachjar.com/flyers/all/schools/25715"
  },
  {
    id: "206",
    slug: "gray",
    name: "Gray",
    websiteURL: "https://gray.tacomaschools.org/",
    contactsURL: "https://gray.tacomaschools.org/contact",
    flyersURL: "https://app.peachjar.com/flyers/all/schools/25734"
  },
  {
    id: "208",
    slug: "hunt",
    name: "Hunt",
    websiteURL: "https://hunt.tacomaschools.org/",
    contactsURL: "https://hunt.tacomaschools.org/contact",
    flyersURL: "https://app.peachjar.com/flyers/all/schools/25720"
  },
  {
    id: "209",
    slug: "bryant",
    name: "Bryant",
    websiteURL: "https://bryant.tacomaschools.org/",
    contactsURL: "https://bryant.tacomaschools.org/contact",
    flyersURL: "https://app.peachjar.com/flyers/all/schools/25720"
  },
  {
    id: "210",
    slug: "hh",
    name: "Hilltop Heritage",
    websiteURL: "https://hh.tacomaschools.org/",
    contactsURL: "https://hh.tacomaschools.org/contact",
    flyersURL: "https://app.peachjar.com/flyers/all/schools/25738"
  },
  {
    id: "212",
    slug: "mason",
    name: "Mason",
    websiteURL: "https://mason.tacomaschools.org/",
    contactsURL: "https://mason.tacomaschools.org/contact",
    flyersURL: "https://app.peachjar.com/flyers/all/schools/25748"
  },
  {
    id: "216",
    slug: "meeker",
    name: "Meeker",
    websiteURL: "https://meeker.tacomaschools.org/",
    contactsURL: "https://meeker.tacomaschools.org/contact",
    flyersURL: "https://app.peachjar.com/flyers/all/schools/25752"
  },
  {
    id: "218",
    slug: "stewart",
    name: "Stewart",
    websiteURL: "https://stewart.tacomaschools.org/",
    contactsURL: "https://stewart.tacomaschools.org/contact",
    flyersURL: "https://app.peachjar.com/flyers/all/schools/25767"
  },
  {
    id: "220",
    slug: "truman",
    name: "Truman",
    websiteURL: "https://truman.tacomaschools.org/",
    contactsURL: "https://truman.tacomaschools.org/contact",
    flyersURL: "https://app.peachjar.com/flyers/all/schools/25770"
  },
  {
    id: "221",
    slug: "fc",
    name: "First Creek",
    websiteURL: "https://fc.tacomaschools.org/",
    contactsURL: "https://fc.tacomaschools.org/contact",
    flyersURL: "https://app.peachjar.com/flyers/all/schools/25750"
  },
  {
    id: "222",
    slug: "wainwright",
    name: "Wainwright",
    websiteURL: "https://wainwright.tacomaschools.org/",
    contactsURL: "https://wainwright.tacomaschools.org/contact",
    flyersURL: "https://app.peachjar.com/flyers/all/schools/25774"
  },
  {
    id: "224",
    slug: "foss",
    name: "Foss",
    websiteURL: "https://foss.tacomaschools.org/",
    contactsURL: "https://foss.tacomaschools.org/contact",
    flyersURL: "https://app.peachjar.com/flyers/all/schools/25729"
  },
  {
    id: "226",
    slug: "lincoln",
    name: "Lincoln",
    websiteURL: "https://lincoln.tacomaschools.org/",
    contactsURL: "https://lincoln.tacomaschools.org/contact",
    flyersURL: "https://app.peachjar.com/flyers/all/schools/25741"
  },
  {
    id: "228",
    slug: "mt",
    name: "Mount Tahoma",
    websiteURL: "https://mt.tacomaschools.org/",
    contactsURL: "https://mt.tacomaschools.org/contact",
    flyersURL: "https://app.peachjar.com/flyers/all/schools/25753"
  },
  {
    id: "230",
    slug: "stadium",
    name: "Stadium",
    websiteURL: "https://stadium.tacomaschools.org/",
    contactsURL: "https://stadium.tacomaschools.org/contact",
    flyersURL: "https://app.peachjar.com/flyers/all/schools/25765"
  },
  {
    id: "232",
    slug: "silas",
    name: "Silas",
    websiteURL: "https://silas.tacomaschools.org/",
    contactsURL: "https://silas.tacomaschools.org/contact",
    flyersURL: "https://app.peachjar.com/flyers/all/schools/25775"
  },
  {
    id: "234",
    slug: "oakland",
    name: "Oakland",
    websiteURL: "https://oakland.tacomaschools.org/",
    contactsURL: "https://oakland.tacomaschools.org/contact",
    flyersURL: "https://app.peachjar.com/flyers/all/schools/25755"
  },
  {
    id: "237",
    slug: "sota",
    name: "SOTA",
    websiteURL: "https://sota.tacomaschools.org/",
    contactsURL: "https://sota.tacomaschools.org/contact",
    flyersURL: "https://app.peachjar.com/flyers/all/schools/25768"
  },
  {
    id: "239",
    slug: "sami",
    name: "SAMi",
    websiteURL: "https://sami.tacomaschools.org/",
    contactsURL: "https://sami.tacomaschools.org/contact",
    flyersURL: "https://app.peachjar.com/flyers/all/schools/107686"
  },
  {
    id: "0",
    slug: "none",
    name: "School",
    websiteURL: "https://www.tacomaschools.org/schools",
    contactsURL: "https://www.tacomaschools.org/schools",
    flyersURL: "https://www.peachjar.com"
  }
];

export function getSchool(s) {
  const school = schools.find(school => school.slug === s);
  return school ? school : schools.find(school => school.slug === "none");
}
