import React from "react";
import { getSchool } from "../services/fakeSchoolsService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGraduationCap,
  faComments,
  faCompass,
} from "@fortawesome/free-solid-svg-icons";

export default function Kiosk() {
  const getQueryVariable = (variable) => {
    let query = window.location.search.substring(1);
    let vars = query.split("&");
    for (let i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if (pair[0] === variable) {
        return pair[1];
      }
    }
    return false;
  };

  const slug = getQueryVariable("s") ? getQueryVariable("s") : "none";

  const school = getSchool(slug);

  return (
    <>
      <header>
        <a href="https://www.tacomaschools.org">
          <img src="/tps-logo.svg" alt="Tacoma Public Schools" />
        </a>
      </header>
      <main className="container">
        <div className="row">
          <div className="kiosk-tile col-md-4">
            <h1>
              <a
                href="https://www.tacomaschools.org/departments/technology/parentvue"
                className="btn btn-block btn-xl btn-primary"
                target="_parent"
              >
                <FontAwesomeIcon icon={faGraduationCap} />
                <br />
                <span className="kiosk-title">ParentVUE</span>
                <span className="kiosk-desc">
                  Check your child's grades and​ more »
                </span>
                <span className="kiosk-desc-es">
                  Revise las calificaciones de su hijo y más
                </span>
              </a>
            </h1>
          </div>
          <div className="kiosk-tile col-md-4">
            <h1>
              <a
                href="https://compass.tpsapps.org/"
                className="btn btn-block btn-xl btn-primary"
                target="_parent"
              >
                <FontAwesomeIcon icon={faCompass} />
                <br />
                <span className="kiosk-title">Compass</span>
                <span className="kiosk-desc">The TPS Family App »</span>
                <span className="kiosk-desc-es">
                  La aplicación de TPS para familias
                </span>
              </a>
            </h1>
          </div>
          <div className="kiosk-tile col-md-4">
            <h1>
              <a
                className="btn btn-block btn-xl btn-primary"
                href={school.contactsURL}
                target="_parent"
              >
                <FontAwesomeIcon icon={faComments} />
                <br />
                <span className="kiosk-title">Teacher Contacts</span>
                <span className="kiosk-desc">
                  Connect with your child's teacher »
                </span>
                <span className="kiosk-desc-es">
                  Conéctese con el maestro de su hijo
                </span>
              </a>
            </h1>
          </div>
        </div>
        <div className="row">
          ​
          <div className="kiosk-tile col-md-6">
            <h2>
              <a
                className="btn btn-block btn-lg btn-primary"
                href={school.flyersURL}
                target="_parent"
              >
                Peachjar: online school flyers
              </a>
            </h2>
          </div>
          <div className="kiosk-tile col-md-6">
            <h2>
              <a
                className="btn btn-block btn-lg btn-primary"
                href={school.websiteURL}
                target="_parent"
              >
                {school.name} Website
              </a>
            </h2>
          </div>
        </div>
        <div className="row">
          <div class="col-md-12">
            <a href="https://www.tacomaschools.org">
              <img
                src={require("../banner.jpg")}
                alt="#webelieve"
                className="img-fluid"
              />
            </a>
          </div>
        </div>
      </main>
    </>
  );
}
